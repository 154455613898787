<!-- 权限列表 -->

<template>
  <div class="main-cnt permission">
    <div class="content">
      <common-table ref="chargeTable" tableHeight="calc(100vh - 325px)" :ischeck="false" :ispaging="true"
        :apiName="SystemManage.getNodeLists" :filters="filters" :columns="tableColumns">
        <template #operate>
          <el-button type="primary" round @click="onAddAuthority">
            <el-icon><i class="iconfont icon-a-lianhe4"></i></el-icon>
            新增权限
          </el-button>
        </template>
      </common-table>
    </div>

    <!-- 新增权限 -->
    <w-dialog ref="refDialog" class="add-dialog" title="添加权限信息" width="50%" btnWidth="140px" top="20vh"
      @wConfirm="addConfirm">
      <el-form class="add-form" ref="addForm" :model="ruleForm" :rules="rules" labelPosition="top">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="所属应用" prop="n_mid">
              <el-select v-model="ruleForm.n_mid" clearable placeholder="请选择所属应用" @change="applicationChange">
                <el-option v-for="(item, index) in applicationOption" :key="index" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="上级节点" prop="n_pid">
              <el-select v-model="ruleForm.n_pid" clearable placeholder="请选择上级节点">
                <el-option v-for="(item, index) in parentNodeOption" :key="index" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="是否菜单" prop="n_is_navigation">
              <el-select v-model="ruleForm.n_is_navigation" clearable placeholder="请选择是否菜单">
                <!-- 类型 1-菜单 2-权限 -->
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="权限名称" prop="n_name">
              <el-input v-model="ruleForm.n_name" placeholder="请输入权限名称"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="控制名称" prop="n_controller_name">
              <el-input v-model="ruleForm.n_controller_name" placeholder="请输入控制名称"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="方法名称" prop="n_action_name">
              <el-input v-model="ruleForm.n_action_name" placeholder="请输入方法名称"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="排序" prop="n_orders">
              <el-input v-model="ruleForm.n_orders" placeholder="请输入排序" type="number" oninput="if(value<0)value=0">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </w-dialog>
  </div>
</template>

<script setup>
  import { ref, reactive, onMounted, computed, watch, nextTick } from "vue";
  import { SystemManage } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";
  import { useStore } from "vuex";

  const store = useStore();
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  const authData = ref([]);
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );
  /** 新增弹框对象 */
  const refDialog = ref(null);
  /** 表格对象 */
  const chargeTable = ref(null);
  /** 表单对象 */
  const addForm = ref(null);
  /** 表单数据对象 */
  const ruleForm = ref({
    n_name: '',  // 权限名称
    n_mid: '',  // 所属应用
    n_is_navigation: '',  // 类型
    n_pid: '',  // 节点
    n_controller_name: '',  // 控制器
    n_action_name: '',  // 方法
    n_orders: '',  // 排序
    n_remark: '',  // 备注
  });
  /** 表单规则对象 */
  const rules = reactive({
    n_name: [
      { required: true, message: "请输入权限名称", trigger: "blur", },
    ],
    // n_pid: [
    //   {
    //     required: true, message: "请选择上级节点", trigger: "change",
    //   },
    // ],
    n_is_navigation: [
      {
        required: true, message: "请选择是否菜单", trigger: "change",
      },
    ],
    n_mid: [
      {
        required: true, message: "请选择所属应用", trigger: "change",
      },
    ],
  });
  /** 筛选条件列表 */
  const filters = ref([
    {
      filterType: "select",
      name: "m_id",
      value: "",
      placeholder: "请选择模块",
      options: [],
      val: "id",
      lab: "name",
      noclear: true,
    },
  ]);
  /** 表格配置数据 */
  const tableColumns = ref([
    {
      prop: "n_id",
      label: "ID",
      color: "--text-color",
    }, {
      prop: "n_pid",
      label: "父级ID",
      color: "--text-color",
    },
    {
      prop: "n_name",
      label: "权限名称",
      color: "--text-color",
    },
    {
      prop: "n_controller_name",
      label: "控制器名称",
      color: "--text-color",
    },
    {
      prop: "n_action_name",
      label: "方法名称",
      color: "--text-color",
    },
    {
      prop: "navigation_text",
      label: "类型",
      color: "--text-color",
    },
    {
      prop: "n_orders",
      label: "排序",
      color: "--text-color",
    }
  ]);
  const applicationOption = ref([]);  // 所属应用选项
  const parentNodeOption = ref([]);  // 上级节点选项
  /**
   * 
   * 模块列表
   * 
   * */
  const getModuleLists = () => {
    SystemManage.getModuleLists().then((res) => {
      if (res.Code === 200) {
        applicationOption.value = res.Data ? res.Data : [];
        filters.value[0].options = res.Data ? res.Data : [];
        filters.value[0].value = res.Data[0].id;
        chargeTable.value.tableLoad();
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
   * 
   * 新增权限按钮
   * 
   * */
  const onAddAuthority = () => {
    ruleForm.value = {
      n_name: '',  // 权限名称
      n_mid: '',  // 所属应用
      n_is_navigation: '',  // 类型
      n_pid: '',  // 节点
      n_controller_name: '',  // 控制器
      n_action_name: '',  // 方法
      n_orders: '',  // 排序
      n_remark: '',  // 备注
    };
    parentNodeOption.value = [];
    refDialog.value.show();
  }
  /**
   * 
   * 选择所属应用
   * 
   * */
  const applicationChange = () => {
    if (!ruleForm.value.n_mid) {
      parentNodeOption.value = [];
      ruleForm.value.n_pid = '';
    } else {
      getTopNodeLists();
    }
  }
  /**
   * 
   * 获取上级节点
   * 
   * */
  const getTopNodeLists = () => {
    SystemManage.getTopNodeLists({ m_id: ruleForm.value.n_mid }).then((res) => {
      if (res.Code === 200) {
        parentNodeOption.value = res.Data ? res.Data : [];
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
   * 
   * 确认保存按钮
   * 
   * */
  const addConfirm = () => {
    addForm.value.validate((valid) => {
      if (valid) {
        let params = JSON.parse(JSON.stringify(ruleForm.value));
        refDialog.value.isLoading = true;  // 按钮加载中状态
        SystemManage.addNodeList(params).then((res) => {
          refDialog.value.isLoading = false;  // 关闭按钮加载状态
          if (res.Code === 200) {
            ElMessage.success("新增成功");  // 成功提示
            refDialog.value.close();  // 关闭弹框
            chargeTable.value.tableLoad();
          } else {
            ElMessage.error(res.Message);
          }
        });
      }
    });
  };

  onMounted(() => {
    getModuleLists();
  });
</script>

<style lang="scss">
  .permission {
    font-family: "Source Han Sans CN";

    .content {
      padding: 20px;
    }

    .add-dialog {
      .el-dialog__body {
        padding: 10px 15px 15px 30px;

        .add-form {
          display: flex;
          flex-wrap: wrap;

          .el-form-item {
            padding-bottom: 20px;
            margin-bottom: 0;

            .el-form-item__content {
              .el-select {
                width: 100%;

                .el-input {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
</style>